












































































































































































































import { PropType } from 'vue'
import { ShippingMixin } from '@/mixins/ShippingMixin'
import { OfferStatusMixin } from '@/mixins/OfferStatusMixin'
import { getOfferImageUrls } from '@/utils/AssetUtil/AssetUtil'
import { PaymentConditionMixin } from '@/mixins/PaymentConditionMixin'
import { IOffer, ISubscription, IUser } from '@/interfaces'
import { OfferType, TooltipPlacement } from '@/enums'
import Thumbnail from '@/components/common/Thumbnail.vue'
import OfferStatusBadge from '@/components/common/OfferStatusBadge/OfferStatusBadge.vue'
import OfferTypeBadge from '@/components/common/OfferTypeBadge.vue'
import { TransactionInfoLink } from '@/components/common/TransactionInfoLink'
import { ITransaction } from '@/modules/transaction/resources'
import { Tooltip } from '@/components/common/Tooltip'
import { ListItem } from '@/components/common/ListItem'
import { FloatingMenu } from '@/components/molecules/FloatingMenu'

export default {
  name: 'OfferListItem',
  components: {
    Thumbnail,
    OfferTypeBadge,
    OfferStatusBadge,
    TransactionInfoLink,
    Tooltip,
    FloatingMenu,
    ListItem,
  },
  mixins: [ShippingMixin, PaymentConditionMixin, OfferStatusMixin],
  inject: ['enums'],
  props: {
    offer: {
      type: Object as PropType<ISubscription>,
      required: true,
    },
    transactions: {
      type: Array as PropType<ITransaction[]>,
      default: () => null,
    },
    isLoadingTransactions: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },
    isDeliveriesAvailable: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },
    isEditable: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },
    isClosable: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },
    isPublishable: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },
    awaitingBids: {
      type: Number as PropType<number>,
      default: 0,
    },
    user: {
      type: Object as PropType<IUser>,
      default: () => null,
    },
  },
  data() {
    return {
      isTransactionLoad: false,
      TooltipPlacement,
    }
  },
  computed: {
    offerFirstImageUrl() {
      const urls = getOfferImageUrls(this.offer)

      return urls.length ? urls[0] : null
    },
    isRecurringOffer() {
      return this.offer?.contractType === this.enums.OfferContractType.Recurring
    },
    isOfferAnonymous() {
      return this.offer?.isAnonymous
    },
    offerStation() {
      return this.offer?.sourceStation || this.offer?.destinationStation
    },
    transactionNumber() {
      return `${this.user.organisation.customerNumber}-${this.offer.offerNumber}`
    },
    offerTypeClass() {
      return this.offer.offerType === OfferType.Buy
        ? this.$style.titleBuy
        : null
    },
    offerTypeColor() {
      return this.offer.offerType === OfferType.Buy
        ? OfferType.Buy
        : OfferType.Sell
    },
    hasActionButtons() {
      return this.isClosable || this.isEditable || this.isPublishable
    },
    stationFormattedAddress() {
      const station = this.offerStation
      return station.addressCity
        ? `${station.addressCountyCode}-${station.addressPostCode}`
        : ''
    },
  },
  methods: {
    onTransaction(offer: IOffer) {
      this.$emit('transaction', offer)
    },
  },
}
