




import { PropType } from 'vue'
import { BidStatus } from '@/enums'

export default {
  name: 'BidStatusBadge',
  props: {
    bidStatus: {
      type: String as PropType<BidStatus>,
      required: true,
    },
  },
  computed: {
    badgeColor() {
      return [BidStatus.Open, BidStatus.Accepted, BidStatus.Closed].includes(
        this.bidStatus
      )
        ? 'success'
        : null
    },
    value() {
      return this.$t(`bidding.status.${this.bidStatus}`)
    },
  },
}
