






























import { IStation } from '@/interfaces'

export default {
  name: 'OfferAttemptInput',
  data() {
    return {
      form: {
        message: '',
      },
      rules: {
        message: {
          required: true,
          trigger: 'none',
          validator: (rule: object, value: IStation, callback: any) => {
            if (!this.form.message.trim()) {
              return callback(
                new Error(this.$t('common.form.attempts.required'))
              )
            }

            return callback()
          },
        },
      },
    }
  },
  methods: {
    async sendAttempt() {
      this.$refs.form.validate((valid: boolean) => {
        if (!valid) {
          return
        }

        const attempt = this.form.message.trim()

        this.$emit('sendAttempt', {
          attempt,
        })
      })
    },
    resetFields() {
      this.$refs.form.resetFields()
    },
    handleInputChange(value: string) {
      if (/[\r\n]/.exec(value)) {
        this.$emit('scroll')
      }
    },
  },
}
