var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('aside',{class:_vm.$style.aside},[_c('trading-dashboard-filter',{attrs:{"filters":_vm.filtersFromQuery},on:{"change":_vm.onFilterChange}})],1),_c('v-main',[_c('loading-spinner',{attrs:{"is-loading":_vm.isLoading}}),(
        _vm.organisationStatus !== _vm.enums.OrganisationStatus.Validated &&
        _vm.organisationStatus !== _vm.enums.OrganisationStatus.Restricted
      )?_c('info-component',{attrs:{"title":_vm.$t(("common.info." + _vm.organisationStatus), {
          companyName: _vm.$appConfig.name,
          date: _vm.organisationValidationLimitDate,
        })}}):_vm._e(),_c('page-title',{attrs:{"title":_vm.$t('subscription.title')}},[(!_vm.isOrganisationRestricted)?_c('floating-menu',{scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((['offer', 'contract']),function(type){return _c('list-item',{key:type,attrs:{"data-wdio-create-new-spot-offer":"","title":_vm.$t(("offer.addNewOffer." + type))},on:{"click":function($event){return _vm.handleNewOfferFloatingMenu(type)}}})})},proxy:true}],null,false,2720623366)},[_c('v-btn',{attrs:{"color":"success","small":"","data-name":"createNewOffer"}},[_vm._v(" "+_vm._s(_vm.$t('offer.addNewOffer.buttonLabel'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)],1):_c('restricted-organisation-button',[_c('v-btn',{attrs:{"slot":"reference","color":"success","small":""},slot:"reference"},[_vm._v(" "+_vm._s(_vm.$t('offer.addNewOffer.buttonLabel'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)],1)],1),_c('custom-dialog',{attrs:{"is-visible":_vm.isInfoDialogVisible,"title":_vm.$t(("common.info.dialogTitle." + _vm.organisationStatus))},on:{"close":_vm.closeInfoDialog}},[_c('confirm-dialog',{attrs:{"on-info-dialog":_vm.onInfoDialogCTA}})],1),_c('custom-dialog',{attrs:{"is-visible":!!_vm.selectedOfferFormItems,"title":_vm.$t(
          ("offerDialog.title." + (_vm.selectedOfferFormItems && _vm.selectedOfferFormItems.id
              ? 'edit'
              : _vm.offerContractType === _vm.enums.OfferContractType.Spot
              ? 'createOffer'
              : 'createContract'))
        )},on:{"close":_vm.closeOfferDialog}},[_c('offer-form',{attrs:{"form-items-data":_vm.selectedOfferFormItems,"has-bids":_vm.hasBids,"contract-type":_vm.offerContractType,"is-edit":_vm.isEdit},on:{"close":_vm.closeOfferDialog,"reset":_vm.resetOfferFormItems}})],1),_c('custom-dialog',{attrs:{"is-visible":_vm.isPublishSubscriptionDialogVisible,"title":_vm.$t('bidding.confirm.publishOffer.title')},on:{"close":_vm.closePublishSubscriptionDialog}},[_c('offer-summary',{attrs:{"offer":_vm.subscription,"action-title":_vm.$t('bidding.actions.publish')},on:{"close-dialog":_vm.closePublishSubscriptionDialog,"submit":function($event){return _vm.publishOffer(_vm.subscription.id)}}})],1),(_vm.subscriptions && _vm.subscriptions.length && !_vm.isLoading)?[_c('offer-list',{attrs:{"offers":_vm.offerDescriptors,"user":_vm.user,"is-deliveries-available":_vm.isDeliveriesAvailable,"is-loading-transactions":_vm.isLoadingTransactions,"transactions-by-offers":_vm.transactionsByOffers},on:{"edit":_vm.openEditSubscriptionDialog,"close":_vm.openCloseSubscriptionDialog,"publish":_vm.openPublishSubscriptionDialog,"redirect":_vm.onOfferRedirect,"transaction":_vm.openTransactionDetailsPage}}),(_vm.tableDataNext)?_c('load-more-button',{attrs:{"is-loading":_vm.isLoadingNext},on:{"click":function($event){return _vm.fetchSubscriptions({ next: _vm.tableDataNext })}}}):_vm._e()]:(!_vm.isLoading)?_c('v-alert',{attrs:{"dense":"","dismissible":"","text":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('warning.noSubscriptionsFound'))+" ")]):_vm._e(),(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.error.message))]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }