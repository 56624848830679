var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.$style.section},[_c('offer-info-item',{scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('header',{class:_vm.$style.header},[(_vm.isRecurring)?_c('span',{staticClass:"title04 bold"},[_vm._v(_vm._s(_vm.$t('transaction.offerTitle', { startDate: _vm.startDate, endDate: _vm.endDate })))]):_c('span',{staticClass:"title04 bold"},[_vm._v(" "+_vm._s(_vm.$tc('transaction.title', 0))+" ")]),(_vm.transactions.length)?_c('delivery-planner'):_vm._e()],1)]},proxy:true}])}),_c('offer-info-item',{scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('div',{class:_vm.$style.table},[_c('v-data-table',{attrs:{"items":_vm.transactionsWithDeliveries(),"headers":_vm.tableHeaders,"disable-filtering":"","disable-sort":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('transaction-status-badge',{attrs:{"transaction-status":item.transactionStatus}})]}},{key:"item.delivery",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateMonth")(item.deliveryFrom))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.calculation)?_c('span',[_vm._v(" "+_vm._s(_vm.getTotalAmount(item))+" "+_vm._s(item.offer.material.unitType)+" ")]):_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(item.calculation)?_c('div',[(item.calculation.indexedPrice)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.calculation.indexedPrice))+"/"+_vm._s(_vm.unitType)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.offer.unitPrice))+"/"+_vm._s(_vm.unitType)+" ")]),(
                  item.offer.unitPriceIndex && !item.calculation.indexedPrice
                )?_c('div',[_vm._v(" "+_vm._s(_vm.$t('common.priceIndex', { group: item.offer.unitPriceIndex.group, }))+" "),_c('span',[_vm._v(_vm._s(item.offer.unitPriceIndex.name))])]):_vm._e()]):_vm._e()]}},{key:"item.deliveries",fn:function(ref){
                var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":600},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-truck-outline")])]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('delivery-info',{attrs:{"delivery":item.deliveries}})],1)],1)],1)]}},{key:"item.download",fn:function(ref){
                var item = ref.item;
return [(_vm.isActiveLink(item))?_c('v-btn',{staticClass:"v-btn__squared-icon",attrs:{"name":item.offer.id,"icon":"","fab":"","outlined":"","x-small":""},on:{"click":function($event){return _vm.downloadTransactionConfirmation(
                  item.offer,
                  item.transactionNumber,
                  item.transactionStatus
                )}}},[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1):_vm._e()]}}])})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }