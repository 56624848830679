

























import { PropType } from 'vue'
import { IUser, IStation, IOrganisation } from '@/interfaces'
import { LocaleCode } from '@/enums'
import VueI18n from 'vue-i18n'

export default {
  name: 'ContactComponent',
  props: {
    contact: {
      type: Object as PropType<IUser | IStation | IOrganisation | undefined>,
      default: undefined,
    },
  },
  data() {
    return {
      LocaleCode,
    }
  },
  computed: {
    rows() {
      if (!this.contact) {
        return []
      }

      const rows = [] as Array<{
        label?: VueI18n.TranslateResult
        value: string
      }>

      if (this.contact.firstName || this.contact.lastName) {
        const nameItems: Array<VueI18n.TranslateResult> = []

        if (this.contact.gender) {
          nameItems.push(this.$t(`common.gender.${this.contact.gender}`))
        }

        if (this.contact.academicRank) {
          nameItems.push(
            this.$t(`common.academicRank.${this.contact.academicRank}`)
          )
        }

        if (this.contact.firstName) {
          nameItems.push(this.contact.firstName)
        }

        if (this.contact.lastName) {
          nameItems.push(this.contact.lastName)
        }

        rows.push({ value: nameItems.join(' ') })
      }

      if (this.contact.position) {
        rows.push({ value: this.contact.position })
      }

      if (this.contact.contactEmail) {
        rows.push({
          label: this.$t('common.label.contactEmailShort'),
          value: `${this.contact.contactEmail}`,
        })
      }

      if (this.contact.contactPhoneNumber) {
        rows.push({
          label: this.$t('common.label.contactPhoneNumberShort'),
          value: `${this.contact.contactPhoneNumber}`,
        })
      }

      if (this.contact.contactMobilePhoneNumber) {
        rows.push({
          label: this.$t('common.label.contactMobilePhoneNumberShort'),
          value: `${this.contact.contactMobilePhoneNumber}`,
        })
      }

      if (this.contact.contactFaxNumber) {
        rows.push({
          label: this.$t('common.label.contactFaxNumberShort'),
          value: `${this.contact.contactFaxNumber}`,
        })
      }

      return rows
    },
  },
}
