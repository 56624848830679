







export default {
  name: 'OfferInfoItem',
  props: {
    offerTypeClass: {
      type: String,
      default: '',
    },
  },
}
