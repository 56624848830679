






import { TransactionStatus } from '@/enums'
import { computed, PropType } from '@vue/composition-api'

export default {
  name: 'TransactionStatusBadge',
  props: {
    transactionStatus: {
      type: String as PropType<TransactionStatus>,
      required: true,
    },
  },
  setup(props: any) {
    const badgeColor = computed(() => {
      switch (props.transactionStatus) {
        case TransactionStatus.Open:
        case TransactionStatus.Free:
          return 'success'
        case TransactionStatus.AwaitingPrice:
        case TransactionStatus.Pending:
          return 'primary'
        case TransactionStatus.Cancelled:
          return 'error'
        default:
          return ''
      }
    })

    return { badgeColor }
  },
}
