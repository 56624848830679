








































































































import { PropType } from 'vue'
import { ITransaction } from '@/modules/transaction/resources'
import { OfferInfoItem } from '@/components/offerDetail/OfferInfoItem'
import { DeliveryPlanner } from '@/components/offerDetail/DeliveryPlanner'
import { DeliveryInfo } from '@/components/offerDetail/DeliveryPlanner/DeliveryInfo'
import { formatDate, groupByMonth, toUnixTime } from '@/utils/utils'
import { TransactionMixin } from '@/mixins/TransactionMixin'
import TransactionStatusBadge from '@/components/common/TransactionStatusBadge.vue'
import { TransactionStatus } from '@/enums'
import { IDeliveriesByDate, IDeliveriesByMonth } from '@/interfaces'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment as any)

export default {
  name: 'OfferTransactionsList',
  components: {
    OfferInfoItem,
    TransactionStatusBadge,
    DeliveryInfo,
    DeliveryPlanner,
  },
  mixins: [TransactionMixin],
  inject: ['enums'],
  props: {
    transactions: {
      type: Array as PropType<ITransaction[]>,
      default: () => [],
    },
    deliveries: {
      type: Object as PropType<IDeliveriesByDate>,
      default: () => null,
    },
    isRecurring: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
  data() {
    return {
      unitType: this.enums.UnitType.Ton,
      transactionsInfo: null,
    }
  },
  computed: {
    startDate() {
      return this.formatUnixDate(this.transactions[0]?.deliveryFrom)
    },
    endDate() {
      return this.formatUnixDate(this.transactions.slice(-1)[0]?.deliveryTo)
    },
    tableHeaders() {
      const shouldShowDowloadColumn = this.transactions.some(
        (transaction: ITransaction) => this.isActiveLink(transaction)
      )

      const headers = [
        {
          text: this.$t('transaction.status.label'),
          width: 140,
          value: 'status',
        },
        { text: this.$t('transaction.number'), value: 'transactionNumber' },
        {
          text: this.$t('transaction.label.deliveryPeriod'),
          value: 'delivery',
        },
        { text: this.$t('common.guaranteedUnitAmount'), value: 'amount' },
        { text: this.$t('common.price'), value: 'price' },
      ] as any[]
      if (this.deliveries && Object.values(this.deliveries).length) {
        headers.push({ text: '', value: 'deliveries' })
      }
      if (shouldShowDowloadColumn) {
        headers.push({ text: '', width: 80, align: 'end', value: 'download' })
      }
      return headers
    },
  },
  methods: {
    formatUnixDate(date: number): string {
      return formatDate(toUnixTime(date))
    },
    isActiveLink(transaction: ITransaction): boolean {
      return [
        TransactionStatus.Open,
        TransactionStatus.AwaitingPrice,
        TransactionStatus.Invoiced,
      ].includes(transaction.transactionStatus)
    },
    getTotalAmount(transaction: ITransaction): number {
      return (
        transaction.deliveryAmount?.totalAmount ||
        (transaction.calculation as any)?.totalAmount ||
        0
      )
    },
    transactionsWithDeliveries() {
      if (!this.deliveries) return this.transactions

      const deliveriesByMonth = groupByMonth(
        this.deliveries
      ) as IDeliveriesByMonth

      return this.transactions.map((transaction: ITransaction) => {
        const { deliveryFrom: from, deliveryTo: to } = transaction

        const range = moment.range(moment.unix(from), moment.unix(to))
        const monthRange = Array.from(range.by('month')).map((i) =>
          i.format('MMMM/YYYY')
        )

        const deliveries = (Object.keys(deliveriesByMonth) as string[]).reduce(
          (acc: any[], month: string) => {
            const deliveryDate = (monthRange as string[]).find(
              (transactionMonth) => transactionMonth === month
            ) as string

            if (!deliveryDate) return [...acc]

            return [...acc, deliveriesByMonth[deliveryDate]]
          },
          []
        )

        return {
          ...transaction,
          deliveries,
        }
      })
    },
  },
}
