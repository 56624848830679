






























































import { IAgreementAttempt, IContract } from '@/modules/contract/state'
import { OfferHeader } from '@/components/offerDetail/OfferHeader'
import { OfferContractList } from '@/components/offerDetail/OfferContractList'
import { OfferNegotiationTitle } from '@/components/offerDetail/OfferNegotiationTitle'
import { OfferInfo } from '@/components/offerDetail/OfferInfo'
import { IBid } from '@/interfaces'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OfferBids',
  components: {
    OfferHeader,
    OfferContractList,
    OfferNegotiationTitle,
    OfferInfo,
  },
  inject: ['enums'],
  computed: {
    ...mapGetters('offer', {
      offer: 'getOffer',
    }),
    ...mapGetters('contract', {
      contracts: 'getContractsAdmin',
      selectedContractId: 'getSelectedContractIdAdmin',
      attempts: 'getAgreementAttemptsAdmin',
      isLoadingContracts: 'getContractsLoader',
      isLoadingAttempts: 'getAttemptsLoader',
    }),
  },
  async created() {
    await this.fetchOffer(this.$route.params.id)
    await this.fetchContractsAdmin(this.offer)
    await this.preselectActiveContract()
  },
  destroyed() {
    this.resetContractsState()
  },
  methods: {
    ...mapActions('offer', ['fetchOffer']),
    ...mapActions('contract', [
      'fetchContractsAdmin',
      'fetchAgreementAttemptsAdmin',
      'setSelectedContractIdAdmin',
      'resetContractsState',
    ]),
    calculateKey(attempt: IAgreementAttempt, index: number) {
      return `${index}-${(attempt.body as IBid).bidStatus}-${
        this.attempts.length
      }`
    },
    async scrollToBottom() {
      await this.$nextTick()
      const container = this.$refs?.negotiations

      if (!container) {
        return
      }

      container.scrollTop =
        container.scrollHeight + container.parentElement.clientHeight
    },
    async preselectActiveContract() {
      if (this.contracts.length) {
        const { contractId } = this.$route.query
        const queryContract = this.contracts.find(
          (contract: IContract) => contract.id === contractId
        )
        const selectedContractId = queryContract
          ? queryContract.id
          : this.contracts[0].id
        await this.setSelectedContractIdAdmin(selectedContractId)
        await this.fetchAgreementAttemptsAdmin({
          contractId: this.selectedContractId,
        })
      }
    },
    getPrevBid(index: number) {
      const currentBidAttempt = this.attempts[this.selectedContractId][index]
      const prevBidAttempt = this.attempts[this.selectedContractId]
        .slice(0, index)
        .reverse()
        .find(
          (attempt: IAgreementAttempt) =>
            attempt?.sender?.organisationId !==
            currentBidAttempt?.sender?.organisationId
        )

      return prevBidAttempt ? prevBidAttempt.body : null
    },
    async onContractSelect(param: { id: string }) {
      await this.setSelectedContractIdAdmin(param.id)
      await this.fetchAgreementAttemptsAdmin({
        contractId: this.selectedContractId,
      })
      await this.scrollToBottom()
    },
    isBidOwner({ organisationId }: IBid) {
      return organisationId === this.offer?.organisationId
    },
  },
}
