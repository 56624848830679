











































































































































































































































import Vue, { PropType } from 'vue'
import { RawLocation, Route } from 'vue-router'
import {
  CommonRatingAggregate,
  IDeliveriesByDate,
  IOffer,
  IPartnerAggregate,
  IPublicRating,
} from '@/interfaces'
import { getOfferImageUrls } from '@/utils/AssetUtil/AssetUtil'
import { OfferHeader } from '@/components/offerDetail/OfferHeader'
import { OfferTransactionsList } from '@/components/offerDetail/OfferTransactionsList'
import { OfferInfoItem } from '@/components/offerDetail/OfferInfoItem'
import OrganisationAvatar from '@/components/common/OrganisationAvatar/OrganisationAvatar.vue'
import AddressComponent from '@/components/common/AddressComponent.vue'
import UserAvatar from '@/components/common/UserAvatar/UserAvatar.vue'
import ContactComponent from '@/components/common/ContactComponent.vue'
import WorkingHoursListComponent from '@/components/common/WorkingHoursListComponent.vue'
import { RatingTabs } from '@/components/common/Rating/RatingTabs'
import FileList from '@/components/common/FileList.vue'
import { ITransaction } from '@/modules/transaction/resources'
import { ImageCarousel } from '@/components/common/ImageCarousel'
import { OfferType } from '@/enums'

export default {
  name: 'OfferDetails',
  components: {
    OfferInfoItem,
    OrganisationAvatar,
    AddressComponent,
    ContactComponent,
    UserAvatar,
    WorkingHoursListComponent,
    OfferHeader,
    FileList,
    OfferTransactionsList,
    RatingTabs,
    ImageCarousel,
  },
  inject: ['enums'],
  beforeRouteEnter(
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
  ) {
    next((vm: any) => {
      if (!vm.offerRevealed && !vm.isGlobalAdmin) {
        next({ path: `/offers/${to.params.id}/contracts`, replace: true })
      }
    })
  },
  props: {
    offer: {
      type: Object as PropType<IOffer>,
      required: true,
      default: () => {},
    },
    transactions: {
      type: Array as PropType<ITransaction[]>,
      default: () => [],
    },
    deliveries: {
      type: Object as PropType<IDeliveriesByDate>,
      required: true,
      default: () => null,
    },
    loadingTransactions: {
      type: Boolean,
      default: () => false,
    },
    offerRevealed: {
      type: Boolean,
      default: () => true,
    },
    isLoadingTransactions: {
      type: Boolean,
      default: () => false,
    },
    partnerRatings: {
      type: Object as PropType<IPartnerAggregate<CommonRatingAggregate>>,
      default: () => null,
    },
    publicRatings: {
      type: Object as PropType<IPublicRating>,
      default: () => null,
    },
    isGlobalAdmin: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
  data() {
    return {
      activeNames: this.isOpened ? ['1'] : [],
    }
  },
  computed: {
    hasOrganisationDocuments() {
      return !!this.offer?.organisation?.documents?.length
    },
    hasStationDocuments() {
      return !!this.offerStation?.documents?.length
    },
    userInfo() {
      if (!this.offer || !this.offer.user) {
        return {}
      }

      const {
        user: {
          firstName,
          lastName,
          gender,
          academicRank,
          position,
          ...contact
        },
      } = this.offer

      return {
        details: {
          firstName,
          lastName,
          gender,
          academicRank,
          position,
        },
        contact,
      }
    },
    isRecurringOffer() {
      return this.offer?.contractType === this.enums.OfferContractType.Recurring
    },
    offerStation() {
      return this.offer.sourceStation || this.offer.destinationStation
    },
    organisationPartnerRatings() {
      return (
        this.partnerRatings && this.partnerRatings[this.offer.organisationId]
      )
    },
    organisationPublicRatings() {
      return this.publicRatings && this.publicRatings[this.offer.organisationId]
    },
    hasRatings() {
      return !!(
        this.organisationPartnerRatings || this.organisationPublicRatings
      )
    },
    offerImageUrls() {
      return getOfferImageUrls(this.offer)
    },
    offerTypeClass() {
      return this.offer.offerType === OfferType.Buy
        ? this.$style.buyOffer
        : this.$style.sellOffer
    },
  },
}
