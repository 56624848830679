






















import { PropType } from 'vue'
import UserAvatar from '@/components/common/UserAvatar/UserAvatar.vue'
import { IAgreementAttempt } from '@/modules/contract/state'
import { IOffer } from '@/interfaces'

export default {
  name: 'OfferNegotiationTitle',
  components: {
    UserAvatar,
  },
  props: {
    meta: {
      type: Object as PropType<IAgreementAttempt> | PropType<IOffer>,
      required: true,
      default: () => {},
    },
    offerRevealed: {
      type: Boolean as PropType<boolean>,
      default: () => true,
    },
  },
}
