














































import { mapActions, mapGetters } from 'vuex'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { ITransaction } from '@/modules/transaction/resources'
import { Dialog } from '@/components/molecules/Dialog'
import { IDeliveriesByDate, IDeliveriesByStatus } from '@/interfaces'
import { DeliveryRawSyncStatus } from '@/enums'
import { formatDate, groupByMonth } from '@/utils/utils'
import { DeliveryMonth } from '@/components/offerDetail/DeliveryPlanner/DeliveryMonth'

const moment = extendMoment(Moment as any)

export default {
  name: 'DeliveryPlanner',
  components: {
    CustomDialog: Dialog,
    DeliveryMonth,
  },
  inject: ['enums'],
  data() {
    return {
      deliveryPlannerForm: {},
      isDeliveryDialogVisible: this.$route.name === 'deliveryPlanner',
      dateRange: {},
      transactionForDeliveries: {},
      isComponentLoading: true,
    }
  },
  computed: {
    ...mapGetters('delivery', ['deliveries', 'isLoading']),
    ...mapGetters('offer', {
      offer: 'getOffer',
    }),
    ...mapGetters('transaction', ['offerTransactions']),
    ...mapGetters('auth', {
      user: 'getUser',
      isGlobalAdmin: 'getIsGlobalAdmin',
    }),
    isBuyer() {
      if (!this.offer && !this.user) return false

      const { offerType, organisationId: offerOrganisationId } = this.offer
      const { organisationId: userOrganisationId } = this.user

      return (
        (offerType === this.enums.OfferType.Sell &&
          offerOrganisationId === userOrganisationId) ||
        (offerType === this.enums.OfferType.Buy &&
          offerOrganisationId !== userOrganisationId)
      )
    },
    activeTabValue() {
      const deliveryMonths = Object.keys(this.deliveryPlannerForm)

      return (
        deliveryMonths.find((date) => date === moment().format('MMMM/YYYY')) ||
        deliveryMonths[0]
      )
    },
    isRecurringOffer() {
      return this.offer?.contractType === this.enums.OfferContractType.Recurring
    },
  },
  async created() {
    this.reloadSchedule()
  },
  async destroyed() {
    this.resetDeliveriesState()
  },
  methods: {
    ...mapActions('delivery', [
      'loadDeliveries',
      'applyChanges',
      'resetDeliveriesState',
    ]),
    formatDeliveryDate(date: number): string {
      return formatDate(date)
    },
    toggleDialogVisibility() {
      this.isDeliveryDialogVisible = !this.isDeliveryDialogVisible
    },
    async reloadSchedule() {
      await this.loadDeliveries(this.offer.id)
      this.calculateDateRange()
      this.groupDeliveriesByMonth()
      this.getTransactionForEachDelivery()
      this.isComponentLoading = false
    },
    async openModal() {
      this.$router.push({
        name: 'deliveryPlanner',
      })
      await this.reloadSchedule()
      this.toggleDialogVisibility()
    },
    async closeModal() {
      await this.$router.push({
        name: 'offer',
        params: { id: this.offer?.id },
      })
      this.toggleDialogVisibility()
      this.resetDeliveriesState()
    },
    calculateDateRange() {
      this.dateRange = {
        from: this.offerTransactions[0]?.deliveryFrom,
        to: this.offerTransactions.slice(-1)[0]?.deliveryTo,
      }
    },
    getTransactionForEachDelivery() {
      this.transactionForDeliveries = (Object.values(
        this.deliveryPlannerForm
      ) as IDeliveriesByDate[]).map((delivery: IDeliveriesByDate) =>
        this.offerTransactions.find((transaction: ITransaction) => {
          const deliveryDates = Object.keys(delivery)
          const { deliveryFrom, deliveryTo } = transaction
          const dateFormat = this.$appConfig.date.formatMomentDate

          const deliveryRange = moment.range(
            moment(deliveryDates[0], dateFormat),
            moment(deliveryDates[deliveryDates.length - 1], dateFormat).endOf(
              'day'
            )
          )
          const transactionRange = moment.range(
            moment.unix(deliveryFrom),
            moment.unix(deliveryTo).endOf('day')
          )

          return transactionRange.overlaps(deliveryRange)
        })
      )
    },
    initializeDeliveryRange() {
      if (!this.offerTransactions || !this.dateRange) return

      const { from, to } = this.dateRange
      const range = moment.range(moment.unix(from), moment.unix(to))

      return Array.from(range.by('day')).reduce(
        (result, day) => ({
          ...result,
          [day.format(this.$appConfig.date.formatMomentDate)]: {
            [DeliveryRawSyncStatus.Open]: 0,
          },
        }),
        {}
      )
    },
    groupDeliveriesByMonth() {
      const defaultValues = this.initializeDeliveryRange()
      const plannedDeliveries = Object.keys(defaultValues).reduce(
        (result, date) => {
          return {
            ...result,
            [date]: {
              ...defaultValues[date],
              ...this.deliveries[date],
            },
          }
        },
        {}
      )

      this.deliveryPlannerForm = groupByMonth(plannedDeliveries)
    },
    calculatePlannedTrucksNumber(deliveries: IDeliveriesByDate) {
      const obj = this.isRecurringOffer
        ? deliveries
        : Object.assign({}, ...Object.values(this.deliveryPlannerForm))

      return (Object.values(obj) as IDeliveriesByStatus[])
        .map(Object.values)
        .flat()
        .filter(Boolean)
        .reduce((a, b) => a + b, 0)
    },
    saveDeliveries() {
      this.applyChanges({
        offerId: this.offer.id,
        deliveriesAggregated: Object.keys(this.deliveryPlannerForm).reduce(
          (acc, month) => ({
            ...acc,
            ...this.deliveryPlannerForm[month],
          }),
          {}
        ),
      }).then(this.closeModal)
    },
  },
}
