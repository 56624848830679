






























import { PropType } from 'vue'
import MaterialTypeTags from '@/components/common/MaterialTypeTags.vue'
import OfferStatusSelect from '@/components/common/OfferStatusSelect.vue'
import OfferTypesCheckboxGroup from '@/components/common/OfferTypesCheckboxGroup.vue'
import CreatedBySelect from '@/components/common/CreatedBySelect.vue'
import { IFilters } from '@/modules/subscription/interfaces'

export default {
  name: 'TradingDashboardFilter',
  components: {
    CreatedBySelect,
    OfferTypesCheckboxGroup,
    MaterialTypeTags,
    OfferStatusSelect,
  },
  inject: ['enums'],
  props: {
    filters: {
      type: Object as PropType<IFilters>,
      required: true,
    },
  },
  data() {
    return {
      activeFilters: this.filters,
    }
  },
  methods: {
    updateFilters() {
      this.$emit('change', this.activeFilters)
    },
  },
}
