



















import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DeliveryFinalMessage',
  inject: ['enums'],
  computed: {
    ...mapGetters('offer', {
      offer: 'getOffer',
    }),
    ...mapGetters('auth', {
      user: 'getUser',
      isGlobalAdmin: 'getIsGlobalAdmin',
    }),
  },
  async created() {
    await this.loadDeliveries(this.offer?.id)
  },
  methods: {
    ...mapActions('delivery', ['loadDeliveries']),
    openDeliveryModal() {
      this.$router.push({
        name: 'deliveryPlanner',
      })
    },
    isBuyer() {
      if (!this.offer && !this.user) return false

      const { offerType, organisationId: offerOrganisationId } = this.offer
      const { organisationId: userOrganisationId } = this.user

      return (
        (offerType === this.enums.OfferType.Sell &&
          offerOrganisationId === userOrganisationId) ||
        (offerType === this.enums.OfferType.Buy &&
          offerOrganisationId !== userOrganisationId)
      )
    },
  },
}
