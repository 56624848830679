






export default {
  name: 'OfferHeader',
}
