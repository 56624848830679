






import { PropType } from 'vue'
import { OfferStatus } from '@/enums'

export default {
  name: 'OfferStatusBadge',
  props: {
    offerStatus: {
      type: String as PropType<OfferStatus>,
      required: true,
    },
  },
  computed: {
    badgeColor() {
      return [
        OfferStatus.Open,
        OfferStatus.Accepted,
        OfferStatus.Closed,
      ].includes(this.offerStatus)
        ? 'success'
        : 'error'
    },
    value() {
      return this.$t(`offer.status.${this.offerStatus}`)
    },
    badgeStyle() {
      if (!this.value || this.value.length < 13) return null
      return this.$style.longText
    },
  },
}
