var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.$style.container},[_c('aside',{class:_vm.$style.aside},[_c('offer-header',{class:_vm.$style.asideHeader},[_c('h1',{staticClass:"hidden-xs-only"},[_vm._v(_vm._s(_vm.$t('tradingOffer.requestTitle')))])]),_c('offer-contract-list',{attrs:{"contracts":_vm.contracts,"attempts":_vm.attempts,"active-contract-id":_vm.selectedContractId},on:{"select":_vm.onContractSelect}})],1),_c('main',{class:_vm.$style.main},[_c('offer-header',{class:[_vm.$style.header, 'align-right']},[_c('h1',[_vm._v(_vm._s(_vm.offer.organisation.name))]),_c('i')]),(
        _vm.selectedContractId &&
        _vm.attempts[_vm.selectedContractId] &&
        _vm.attempts[_vm.selectedContractId].length
      )?_c('div',{ref:"negotiations",class:_vm.$style.negotiations},_vm._l((_vm.attempts[_vm.selectedContractId]),function(attempt,index){return _c('section',{key:index,class:[
          _vm.$style.message,
          _vm.isBidOwner(attempt.body) ? _vm.$style.messageRight : '' ]},[_c('offer-negotiation-title',{attrs:{"meta":attempt}}),_c('div',{class:_vm.$style.attempt,attrs:{"data-name":"bidInfo"}},[_c('offer-info',{attrs:{"offer":_vm.offer,"bid":attempt.body,"prev-bid":_vm.getPrevBid(index)}})],1)],1)}),0):(
        _vm.selectedContractId &&
        _vm.attempts[_vm.selectedContractId] &&
        !_vm.attempts[_vm.selectedContractId].length
      )?_c('section',{class:_vm.$style.negotiationEmpty},[_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-alert-circle")]),_c('strong',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('common.noBids')))])],1)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }