






























































































import { mapActions, mapGetters } from 'vuex'
import { OfferContractList } from '@/components/offerDetail/OfferContractList'
import { OfferAttemptInput } from '@/components/offerDetail/OfferAttemptInput'
import { OfferHeader } from '@/components/offerDetail/OfferHeader'
import { OfferNegotiation } from '@/components/offerDetail/OfferNegotiation'
import { PropType } from 'vue'
import { IOffer, IRelationPartner, IUser, IBid } from '@/interfaces'
import { IContract } from '@/modules/contract/state'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'
import { OfferNegotiationTitle } from '@/components/offerDetail/OfferNegotiationTitle'

export default {
  name: 'OfferTrading',
  components: {
    MarkdownComponent,
    OfferNegotiation,
    OfferContractList,
    OfferAttemptInput,
    OfferHeader,
    OfferNegotiationTitle,
  },
  inject: ['enums'],
  props: {
    offer: {
      type: Object as PropType<IOffer>,
      required: true,
      default: () => null,
    },
    contracts: {
      type: Array as PropType<IContract[]>,
      required: true,
      default: () => [],
    },
    user: {
      type: Object as PropType<IUser>,
      default: () => null,
    },
    offerRevealed: {
      type: Boolean as PropType<Boolean>,
      default: () => true,
    },
    negotiationRevealed: {
      type: Boolean as PropType<Boolean>,
      default: () => true,
    },
    messageRevealed: {
      type: Boolean as PropType<Boolean>,
      default: () => true,
    },
    publicRatings: {
      type: Object as PropType<Record<string, IRelationPartner>>,
      default: () => null,
    },
    partnerRatings: {
      type: Object as PropType<Record<string, IRelationPartner>>,
      default: () => null,
    },
  },
  data() {
    return {
      asideVisible: false,
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters('contract', {
      attempts: 'getAgreementAttempts',
      isAttemptsLoading: 'getAttemptsLoader',
      selectedContractId: 'getSelectedContractId',
      isContractsLoading: 'getContractsLoader',
    }),
    isOfferOwner() {
      return this.offer?.organisationId === this.user?.organisationId
    },
    isOfferAnonymous() {
      return this.offer?.isAnonymous
    },
    getActiveContractOrganisationName() {
      if (!this.contracts.length) {
        return null
      }
      const activeContract = this.contracts.find(
        (contract: IContract) => contract.id === this.selectedContractId
      )

      return activeContract && activeContract.organisation.name
    },
    isOfferInactive() {
      return [
        this.enums.OfferStatus.Rejected,
        this.enums.OfferStatus.Expired,
        this.enums.OfferStatus.Cancelled,
        this.enums.OfferStatus.Closed,
      ].includes(this.offer.offerStatus)
    },
  },
  watch: {
    contracts: async function contractsWatcher() {
      await this.preselectActiveContract()
    },
    attempts: {
      deep: true,
      handler /* istanbul ignore next */() {
        this.scrollToBottom()
      },
    },
  },
  async created() {
    await this.preselectActiveContract()
  },
  methods: {
    ...mapActions('contract', [
      'fetchAgreementAttempts',
      'setSelectedContractId',
      'addAgreementAttempt',
    ]),
    ...mapActions('auth', ['toggleUserProfileModal']),
    async scrollToBottom() {
      await this.$nextTick()
      const container = this.$refs?.negotiations

      if (!container) {
        return
      }

      container.scrollTop =
        container.scrollHeight + container.parentElement.clientHeight
    },
    async onContractSelect(param: { id: string }) {
      await this.setSelectedContractId(param.id)
      this.$router.replace({ query: { contractId: this.selectedContractId } })
      await this.fetchAgreementAttempts({ contractId: this.selectedContractId })
      await this.scrollToBottom()
    },
    onSendAttempt({ attempt }: any) {
      this.toggleUserProfileModal().then(() => {
        this.addAgreementAttempt({
          contractId: this.selectedContractId,
          attempt,
          offer: this.offer,
        })
        this.$refs.attemptInput.resetFields()
      })
    },
    actionAccept(bid: IBid) {
      this.$emit('accept', bid)
    },
    actionCancel(bid: IBid) {
      this.$emit('cancel', bid)
    },
    actionCreate(bid: IBid) {
      this.$emit('create', bid)
    },
    async preselectActiveContract() {
      if (this.contracts.length) {
        const { contractId } = this.$route.query
        const queryContract = this.contracts.find(
          (contract: IContract) => contract.id === contractId
        )
        const selectedContractId = queryContract
          ? queryContract.id
          : this.contracts[0].id
        await this.setSelectedContractId(selectedContractId)
        await this.fetchAgreementAttempts({
          contractId: this.selectedContractId,
        })
      }
    },
    switchAside() {
      this.asideVisible = !this.asideVisible
    },
  },
}
