































































































































































































































































































































































































































































































































import { defineComponent, PropType } from '@vue/composition-api'
import { IOffer, IBid } from '@/interfaces'
import { OfferType } from '@/enums'
import { ShippingMixin } from '@/mixins/ShippingMixin'
import { PaymentConditionMixin } from '@/mixins/PaymentConditionMixin'
import { OfferInfoItem } from '@/components/offerDetail/OfferInfoItem'
import BidStatusBadge from '@/components/common/BidStatusBadge/BidStatusBadge.vue'
import OfferStatusBadge from '@/components/common/OfferStatusBadge/OfferStatusBadge.vue'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'
import { formatPrice } from '@/utils/utils'
import isEqual from 'lodash/isEqual'
import useOfferDetail from '@/components/offerDetail/composable'

export default defineComponent({
  name: 'OfferInfo',
  components: {
    OfferInfoItem,
    OfferStatusBadge,
    BidStatusBadge,
    MarkdownComponent,
  },
  mixins: [ShippingMixin, PaymentConditionMixin],
  inject: ['enums'],
  props: {
    offer: {
      type: Object as PropType<IOffer>,
      required: true,
      default: () => {},
    },
    bid: {
      type: Object as PropType<IBid>,
      default: () => {},
    },
    prevBid: {
      type: Object as PropType<IBid | null>,
      default: () => null,
    },
    isBidOwner: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isOfferOwner: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isCollapsed: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isEditButtonVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isCancelButtonVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isCreateButtonVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isAcceptButtonVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isOfferStatusBadgeVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, context) {
    const { isOfferTypeBuy, isStationInfoRevealed } = useOfferDetail(
      context,
      props.offer
    )

    return {
      isStationInfoRevealed,
      isOfferTypeBuy,
    }
  },
  data() {
    return {
      expandedPanel: this.isCollapsed ? null : 0,
    }
  },
  computed: {
    hasVisibleActionButtons() {
      return [
        this.isEditButtonVisible,
        this.isCancelButtonVisible,
        this.isCreateButtonVisible,
        this.isAcceptButtonVisible,
      ].includes(true)
    },
    diff() {
      const skippedAttributes = [
        'id',
        'bids',
        'viewsCount',
        'userId',
        'user',
        'createdAt',
        'organisation',
        'organisationId',
      ]

      const diff = Object.keys(
        Object.assign({}, this.offer, {
          sourceStation: null,
          destinationStation: null,
        })
      )
        .filter((key) => !skippedAttributes.includes(key))
        .reduce((result, key) => {
          const changes = this.getChange(key)

          return changes
            ? {
                ...result,
                [key]: changes,
              }
            : result
        }, {})

      return diff
    },
    isContractTypeRecurring(): boolean {
      return this.offer.contractType === this.enums.OfferContractType.Recurring
    },
    isEqualDeliveryAmount(): boolean {
      if (!this.bid) return true

      if (
        this.offer?.deliverySchedule.length !==
        this.bid?.deliverySchedule.length
      )
        return true

      return isEqual(this.offer?.deliverySchedule, this.bid?.deliverySchedule)
    },
    deliverySchedule() {
      return this.bid?.deliverySchedule || this.offer?.deliverySchedule
    },
    isOfferAnonymous() {
      return this.offer?.isAnonymous
    },
    offerStation() {
      return this.offer.sourceStation || this.offer.destinationStation
    },
    bidStation() {
      return this.bid.sourceStation || this.bid.destinationStation
    },
    buttonActions() {
      return {
        create: {
          isVisible: this.isCreateButtonVisible,
          handler: this.onCreate,
          color: 'primary',
          icon: 'mdi-plus',
        },
        edit: {
          isVisible: this.isEditButtonVisible,
          handler: this.onEdit,
          color: 'info',
          icon: 'mdi-pencil-outline',
        },
        cancel: {
          isVisible: this.isCancelButtonVisible,
          handler: this.onCancel,
          color: 'error',
          icon: 'mdi-close',
        },
        accept: {
          isVisible: this.isAcceptButtonVisible,
          handler: this.onAccept,
          color: 'success',
          icon: 'mdi-check',
        },
      }
    },
  },
  methods: {
    expiresAt(bid: IBid) {
      return bid && bid.expiresAt ? bid.expiresAt : this.offer.expiresAt
    },
    getChange(property: string) {
      const prevBid = this.prevBid || this.offer
      const currentBid = this.bid || this.offer
      const prev = prevBid[property]
      const current = currentBid[property]

      // if current and prev values are not set, assume that we should use it from offer
      if (current === undefined && prev === undefined) {
        return { current: this.offer[property] }
      }

      // if current value is not set, assume that we should use prev
      if (current === undefined) {
        return { current: prev }
      }

      if (typeof current === 'object' && current !== null && 'id' in current) {
        return prev?.id !== current.id ? { prev, current } : { current }
      }

      return prev !== current ? { prev, current } : { current }
    },
    isChanged(property: string) {
      return this.diff[property] && this.diff[property].prev
    },
    offerTypeClass() {
      return this.offer.offerType === OfferType.Buy
        ? this.$style.buyOffer
        : this.$style.sellOffer
    },
    offerInfoTypeClass() {
      if (!this.bid) {
        return ''
      }

      if (this.bid.bidStatus !== this.enums.BidStatus.Open) {
        return this.$style.bidInactive
      }

      return this.offer.offerType === OfferType.Buy
        ? this.$style.buyOfferBid
        : this.$style.sellOfferBid
    },
    setDiffClass(trigger: boolean) {
      return trigger ? 'itemWithDiff' : ''
    },
    offerFee() {
      return this.offer
        ? `${formatPrice(this.offer?.materialType?.transactionFee)}`
        : ''
    },
    isShippingIncluded() {
      return this.bid?.isShippingIncluded
    },
    onCancel(params: any) {
      this.$emit('cancel', params)
    },
    onCreate(params: any) {
      this.$emit('create', params)
    },
    onEdit(params: any) {
      this.$emit('edit', params)
    },
    onAccept(params: any) {
      this.$emit('accept', params)
    },
  },
})
