import { downloadFile, openUrl } from '@/utils/DocumentUtil/DocumentUtil'
import enums, { LocaleCode, S3DocumentType } from '@/enums'
import { i18n } from '@/i18n'
import { IOffer } from '@/interfaces'

export const TransactionMixin = {
  methods: {
    async downloadTransactionConfirmation(
      offer: IOffer,
      transactionNumber: string,
      transactionStatus: string
    ) {
      const { unitPriceIndexId } = offer
      const priceUpdated =
        unitPriceIndexId && transactionStatus === enums.TransactionStatus.Open

      try {
        const userLocale = i18n.locale ?? LocaleCode.DE
        const priceSuffix = priceUpdated ? '_price_updated' : ''
        const { url } = await downloadFile(
          transactionNumber,
          S3DocumentType.Transaction,
          `${transactionNumber}${priceSuffix}.${userLocale.toLowerCase()}.pdf`
        )

        openUrl(url)
      } catch (error) {
        this.$message.error(error.message)
      }
    },
  },
}
