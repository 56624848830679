

































import { PropType } from 'vue'
import { IOffer, IBid } from '@/interfaces'
import omit from 'lodash/omit'
import { Dialog } from '@/components/molecules/Dialog'
import OfferForm from '@/components/offer/OfferForm.vue'
import moment from 'moment'
import { OriginType } from '@/enums'

export default {
  name: 'OfferRecreate',
  components: {
    CustomDialog: Dialog,
    OfferForm,
  },
  inject: ['enums'],
  props: {
    offer: {
      type: Object as PropType<IOffer>,
      required: true,
    },
    bid: {
      type: Object as PropType<IBid>,
      required: true,
    },
    offerOwner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOfferFormItems: null,
      restUnitAmount: Number(this.offer.unitAmount - this.bid.unitAmount),
    }
  },
  computed: {
    isShowRecreateOffer() {
      return this.offerOwner && this.restUnitAmount > 0
    },
    isContractTypeRecurring() {
      return this.offer?.contractType === this.enums.OfferContractType.Recurring
    },
  },
  methods: {
    closeOfferDialog() {
      this.selectedOfferFormItems = null
    },
    openOfferCreateModal() {
      const omitedOffer = omit(this.offer, ['id', 'bids'])

      this.selectedOfferFormItems = {
        ...omitedOffer,
        unitAmount: this.restUnitAmount,
        deliveryDate: [this.offer.deliveryDateFrom, this.offer.deliveryDateTo],
        expiresAt: moment.unix(this.offer.expiresAt),
        originType: OriginType.Offer,
        originId: this.offer.id,
      }
    },
    async onOfferPublish(id: string) {
      await this.$router.push({ name: 'offer', params: { id } })
    },
  },
}
