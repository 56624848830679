
















import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'ImageCarousel',
  props: {
    imageUrls: {
      type: Array as PropType<string[]>,
      required: true,
      default: [] as string[],
    },
    height: {
      type: Number as PropType<number>,
      default: 384,
    },
    cycleInterval: {
      type: Number as PropType<number>,
      default: 4000,
    },
  },
  setup(props) {
    const uniqueImageUrls = computed(() => [...new Set(props.imageUrls)])

    return { uniqueImageUrls }
  },
})
