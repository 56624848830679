













































import OrganisationAvatar from '@/components/common/OrganisationAvatar/OrganisationAvatar.vue'
import { IContractAgreementAttempts, IContract } from '@/modules/contract/state'
import { RatingTabsPopover } from '@/components/common/Rating/RatingTabsPopover'
import { PropType } from 'vue'
import { IRelationPartner } from '@/interfaces'

export default {
  name: 'OfferContractList',
  components: {
    OrganisationAvatar,
    RatingTabsPopover,
  },
  inject: ['enums'],
  props: {
    contracts: {
      type: Array as PropType<Array<IContract>>,
      required: true,
      default: () => [],
    },
    attempts: {
      type: Object as PropType<IContractAgreementAttempts>,
      default: () => null,
    },
    activeContractId: {
      type: String as PropType<string | null>,
      default: null,
    },
    publicRatings: {
      type: Object as PropType<Record<string, IRelationPartner>>,
      default: () => null,
    },
    partnerRatings: {
      type: Object as PropType<Record<string, IRelationPartner>>,
      default: () => null,
    },
  },
  methods: {
    selectActive(id: string) {
      if (id !== this.activeContractId) {
        this.$emit('select', { id })
      }
    },
    isAttemptIndicatorVisible(contract: IContract) {
      return contract.hasPendingAttempt || contract.hasAcceptedAttempt
    },
    indicatorStyleName(contract: IContract) {
      if (contract.hasAcceptedAttempt) {
        return this.$style.acceptedAttemptIndicator
      }

      if (contract.hasPendingAttempt) {
        return this.$style.pendingAttemptIndicator
      }

      return ''
    },
    organisationNameAcronym(name: string) {
      return name
        .split(/\s/)
        .reduce((i: string, j: string) => (i += j.slice(0, 1)), '')
        .substring(0, 3)
        .toUpperCase()
    },
  },
}
