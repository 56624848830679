













































import { PropType } from 'vue'
import { IAgreementAttempt } from '@/modules/contract/state'
import { OfferInfo } from '@/components/offerDetail/OfferInfo'
import { IOffer, IUser, IBid } from '@/interfaces'
import { AttemptType, OfferStatus } from '@/enums'
import { OfferFinalMessage } from '@/components/offerDetail/OfferFinalMessage'
import { OfferNegotiationTitle } from '@/components/offerDetail/OfferNegotiationTitle'

export default {
  name: 'OfferNegotiation',
  components: {
    OfferInfo,
    OfferFinalMessage,
    OfferNegotiationTitle,
  },
  inject: ['enums'],
  props: {
    attempts: {
      type: Array as PropType<IAgreementAttempt[]>,
      required: true,
      default: () => [],
    },
    offer: {
      type: Object as PropType<IOffer>,
      default: null,
    },
    user: {
      type: Object as PropType<IUser>,
      required: true,
      default: () => null,
    },
    offerRevealed: {
      type: Boolean as PropType<boolean>,
      default: () => true,
    },
    negotiationRevealed: {
      type: Boolean as PropType<boolean>,
      default: () => true,
    },
  },
  computed: {
    lastAttemptId() {
      return this.getBids().pop().id
    },
    transactionNumber() {
      return `${this.user.organisation.customerNumber}-${this.offer.offerNumber}`
    },
    shouldRevealAcceptButton() {
      return this.isOfferOwner() ? this.negotiationRevealed : this.offerRevealed
    },
  },
  methods: {
    getPrevBid(index: number) {
      const currentBidAttempt = this.attempts[index]
      const prevBidAttempt = this.attempts
        .slice(0, index)
        .filter(
          (attempt: IAgreementAttempt) => attempt.type === AttemptType.Bid
        )
        .reverse()
        .find(
          (attempt: IAgreementAttempt) =>
            attempt?.sender?.organisationId !==
            currentBidAttempt?.sender?.organisationId
        )

      return prevBidAttempt ? prevBidAttempt.body : null
    },
    isMyOrganisation(sender: IUser) {
      return sender?.organisationId === this.user?.organisationId
    },
    isBidOwner(organisationId: string) {
      return organisationId === this.user?.organisationId
    },
    isOfferOwner() {
      return this.offer?.organisationId === this.user?.organisationId
    },
    actionCancel(id: number) {
      this.$emit('cancel', id)
    },
    actionEdit(id: number) {
      this.$emit('edit', id)
    },
    actionCreate(id: number) {
      this.$emit('create', id)
    },
    actionAccept(id: number) {
      this.$emit('accept', id)
    },
    calculateKey(attempt: IAgreementAttempt, index: number) {
      const bid = (attempt.body as IBid) || {}
      if (attempt.type === AttemptType.Text && bid) {
        return index
      }
      return `${index}-${bid.bidStatus}-${this.attempts.length}`
    },
    getBids() {
      return this.attempts.filter((attempt: IAgreementAttempt) => {
        return attempt.type === this.enums.AttemptType.Bid
      })
    },
    getActionButtonsConditions(attempt: IAgreementAttempt) {
      const bid = (attempt.body as IBid) || {}

      if (
        this.offer.offerStatus !== OfferStatus.Open ||
        ![this.enums.BidStatus.Cancelled, this.enums.BidStatus.Open].includes(
          bid.bidStatus
        )
      ) {
        return {}
      }

      const isBidOwner = this.isBidOwner(bid.organisationId)
      const isLastBid = this.getBids().pop().id === attempt.id
      const isCancelledBid = bid.bidStatus === this.enums.BidStatus.Cancelled

      return {
        isAcceptButtonVisible:
          !isBidOwner &&
          isLastBid &&
          !isCancelledBid &&
          this.shouldRevealAcceptButton,
        isCancelButtonVisible: isBidOwner && isLastBid && !isCancelledBid,
        isCreateButtonVisible: isLastBid,
      }
    },
  },
}
