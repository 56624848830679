














































































































import { PropType } from 'vue'
import moment from 'moment'
import { NumberField } from '@/components/molecules/NumberField'
import { OfferType, DeliveryRawSyncStatus } from '@/enums'
import { IDeliveriesByDate, IOffer } from '@/interfaces'
import { ITransaction } from '@/modules/transaction/resources'

export default {
  components: { NumberField },
  props: {
    monthDeliveries: {
      type: Object as PropType<IDeliveriesByDate>,
      required: true,
    },
    transaction: {
      type: Object as PropType<ITransaction>,
      required: true,
    },
    offer: {
      type: Object as PropType<IOffer>,
      required: true,
    },
    plannedTrucksNumber: {
      type: Number as PropType<number>,
      default: () => 0,
    },
  },
  data() {
    return {
      deliveries: this.monthDeliveries,
      inputFocused: '',
    }
  },
  computed: {
    totalNumberOfTrucks() {
      return Math.floor(
        this.transaction?.deliveryAmount?.totalAmount /
          this.transaction?.offer?.guaranteedUnitAmount
      )
    },
    deliveryProgress() {
      if (!this.totalNumberOfTrucks) return 0
      const decimal =
        (this.plannedTrucksNumber / this.totalNumberOfTrucks) * 100

      return decimal < 50 ? Math.ceil(decimal) : Math.floor(decimal)
    },
  },
  methods: {
    offerTypeClass() {
      return this.offer?.offerType === OfferType.Buy
        ? this.$style.buyOffer
        : this.$style.sellOffer
    },
    isOpenStatus(status: string): boolean {
      return status === DeliveryRawSyncStatus.Open
    },
    isWeekend(date: string): boolean {
      const day = moment(date, this.$appConfig.date.formatMomentDate).day()
      return day === 6 || day === 0
    },
    isLastDayOfWeek(date: string): boolean {
      return moment(date, this.$appConfig.date.formatMomentDate).day() === 0
    },
    isInFuture(date: string): boolean {
      return moment(date, this.$appConfig.date.formatMomentDate).isAfter(
        moment()
      )
    },
    calculateMaxInputValue(value: number): number {
      return this.totalNumberOfTrucks - this.plannedTrucksNumber + value
    },
    isDeliverySyncStatusOpen(status: string): boolean {
      return status === DeliveryRawSyncStatus.Open
    },
    onInputFocus(input: string) {
      this.inputFocused = input
    },
    onInputBlur() {
      this.inputFocused = ''
    },
  },
}
