




















































































































import { mapActions, mapGetters } from 'vuex'
import OfferForm from '@/components/offer/OfferForm.vue'
import OfferSummary from '@/components/offer/OfferSummary.vue'
import { OfferList } from '../OfferList'
import { TradingDashboardFilter } from '../TradingDashboardFilter'
import { PageTitle } from '@/components/atoms/PageTitle'
import { Dialog } from '@/components/molecules/Dialog'
import { IOfferDescriptor, ISubscription, IBid } from '@/interfaces'
import { IFilters } from '@/modules/subscription/interfaces'
import InfoComponent from '@/components/common/InfoComponent/InfoComponent.vue'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import { RestrictedOrganisationButton } from '@/components/common/RestrictedOrganisationButton'
import { FloatingMenu } from '@/components/molecules/FloatingMenu'
import { ListItem } from '@/components/common/ListItem'
import { LoadingSpinner } from '@/components/atoms/LoadingSpinner'
import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'
import { formatDate } from '@/utils/utils'
import moment from 'moment'
import {
  getFormItemsFromOffer,
  getDefaultFormItems,
  cancelOffer,
  publishOffer,
} from '@/utils/OfferUtil/OfferUtil'
import { Emitter } from '@/enums'

interface IBidsByGroupId {
  [bidGroupId: string]: IBid[]
}

export default {
  name: 'TradingDashboard',
  components: {
    PageTitle,
    OfferList,
    TradingDashboardFilter,
    CustomDialog: Dialog,
    InfoComponent,
    ConfirmDialog,
    OfferForm,
    OfferSummary,
    FloatingMenu,
    RestrictedOrganisationButton,
    ListItem,
    LoadingSpinner,
    LoadMoreButton,
  },
  inject: ['enums'],
  data() {
    return {
      isAsideVisible: false,
      isInfoDialogVisible: false,
      messageEvent: null,
      selectedOfferFormItems: null,
      selectedOffer: null,
      subscription: null,
      isPublishSubscriptionDialogVisible: false,
      offerContractType: this.enums.OfferContractType.Spot,
      isEdit: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
      isGlobalAdmin: 'getIsGlobalAdmin',
      organisationStatus: 'getOrganisationStatus',
      isDeliveriesAvailable: 'isDeliveriesAvailable',
      isOrganisationRestricted: 'isOrganisationRestricted',
    }),
    ...mapGetters('subscription', {
      isLoading: 'getIsLoading',
      error: 'getError',
      subscriptions: 'getSubscriptions',
      filtersFromQuery: 'getFiltersFromQuery',
      queryFromFilters: 'getQueryFromFilters',
      isLoadingNext: 'getIsLoadingNext',
      tableDataNext: 'getTableDataNext',
    }),
    ...mapGetters('transaction', [
      'offerTransactions',
      'isLoadingTransactions',
      'transactionsByOffers',
    ]),
    hasBids() {
      return !!this.selectedOffer?.bids?.length
    },
    offerDescriptors() {
      return this.subscriptions.map(
        (subscription: ISubscription): IOfferDescriptor => ({
          offer: subscription,
          awaitingBids: this.getAwaitingBidsNumber(subscription),
          isPublishable: this.isOfferPublishable(subscription),
          isClosable: this.isSubscriptionClosable(subscription),
          isEditable: this.isOfferEditable(subscription),
        })
      )
    },
    mainClass() {
      const contentStyle = [this.$style.content]

      if (this.isAsideVisible) {
        contentStyle.push(this.$style.contentAsideVisible)
      }
      return contentStyle
    },
    organisationValidationLimitDate() {
      return formatDate(
        moment(this.user?.organisation.createdAt)
          .add(this.$appConfig.organisationValidationLimitDays, 'days')
          .unix()
      )
    },
  },
  async created() {
    await this.fetchFiltersFromQuery(this.$route.query)
    await this.fetchSubscriptions()
  },
  destroyed() {
    this.resetOfferTransactions()
  },
  methods: {
    ...mapActions('subscription', [
      'fetchSubscriptions',
      'setSubscriptionFilters',
      'fetchFiltersFromQuery',
    ]),
    ...mapActions('auth', ['toggleUserProfileModal']),
    ...mapActions('transaction', [
      'fetchOfferTransactions',
      'resetOfferTransactions',
      'fetchTransactionsByOffers',
    ]),
    isSubscriptionClosable(subscription: ISubscription) {
      const { OfferStatus } = this.enums

      if (this.user && subscription.organisation) {
        if (subscription.organisation.id === this.user.organisation.id) {
          return [OfferStatus.Draft, OfferStatus.Open].includes(
            subscription.offerStatus
          )
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isOfferPublishable({ organisationId, offerStatus }: ISubscription) {
      return (
        organisationId === this.user?.organisationId &&
        offerStatus === this.enums.OfferStatus.Draft
      )
    },
    isOfferEditable({ organisationId, offerStatus }: ISubscription) {
      const { OfferStatus } = this.enums

      return (
        organisationId === this.user?.organisationId &&
        ![
          OfferStatus.Closed,
          OfferStatus.Expired,
          OfferStatus.Cancelled,
          OfferStatus.Accepted,
        ].includes(offerStatus)
      )
    },
    getAwaitingBidsNumber({
      offerStatus,
      bids,
      organisationId,
    }: ISubscription): number {
      if (
        offerStatus !== this.enums.OfferStatus.Open ||
        !bids ||
        !bids.length ||
        !this.user
      ) {
        return 0
      }

      const bidsByGroupId: IBidsByGroupId = bids.reduce(
        (result: any, bid: IBid) => {
          const { bidGroupId } = bid

          return {
            ...result,
            [bidGroupId]: [...(result[bidGroupId] || []), bid],
          }
        },
        {}
      )

      return Object.keys(bidsByGroupId).reduce(
        (total: number, groupId: string) => {
          const bidsGroup = bidsByGroupId[groupId]
          const userOrganisationId = this.user?.organisationId
          const firstBid = bidsGroup.slice(-1)[0]

          if (
            organisationId !== userOrganisationId &&
            firstBid?.organisationId !== userOrganisationId
          ) {
            return 0
          }

          const lastBid = bidsGroup[0]

          return lastBid.bidStatus === this.enums.BidStatus.Open &&
            userOrganisationId !== lastBid?.organisationId
            ? total + 1
            : total
        },
        0
      )
    },
    async onFilterChange(filters: IFilters) {
      await this.$router.push({
        query: this.queryFromFilters,
      })
      await this.setSubscriptionFilters(filters)
      await this.fetchSubscriptions()
    },
    handleNewOfferFloatingMenu(command: string) {
      switch (command) {
        case 'offer':
          this.offerContractType = this.enums.OfferContractType.Spot
          break
        case 'contract':
          this.offerContractType = this.enums.OfferContractType.Recurring
          break
      }

      return this.openEditSubscriptionDialog(null, false)
    },
    openEditSubscriptionDialog(
      subscription: ISubscription | null,
      editable: boolean = false
    ) {
      this.toggleUserProfileModal().then(() => {
        if (
          [
            this.enums.OrganisationStatus.Draft,
            this.enums.OrganisationStatus.Ready,
          ].includes(this.organisationStatus)
        ) {
          this.isInfoDialogVisible = true
        } else {
          this.selectedOffer = subscription
          if (subscription) {
            this.offerContractType = subscription.contractType
          }

          this.isEdit = editable

          this.selectedOfferFormItems = subscription
            ? getFormItemsFromOffer(subscription)
            : getDefaultFormItems()
        }
      })
    },
    openPublishSubscriptionDialog(subscription: ISubscription) {
      // TODO: use same var for selected subscriptions
      this.subscription = subscription
      this.isPublishSubscriptionDialogVisible = true
    },
    async openTransactionDetailsPage(subscription: ISubscription) {
      await this.fetchTransactionsByOffers(subscription.id)

      const transaction = this.transactionsByOffers[subscription.id]

      if (transaction.length > 1) {
        return
      }

      this.$router.push({
        name: 'transaction',
        params: { id: transaction?.[0]?.id },
      })
    },
    closePublishSubscriptionDialog() {
      this.isPublishSubscriptionDialogVisible = false
      this.isEdit = false
    },
    openCloseSubscriptionDialog(subscription: ISubscription) {
      this.$confirm(
        this.$t('bidding.confirm.closeOffer.content', {
          cta: this.$t('common.ok'),
        }),
        this.$t('bidding.confirm.closeOffer.title'),
        {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.dialog.cancel'),
        }
      )
        .then(async () => {
          try {
            await cancelOffer(subscription.id)
            this.$message({
              type: 'success',
              message: this.$t('bidding.confirm.closeOffer.acceptMessage'),
            })
            this.$store.commit('subscription/SET_IS_LOADING', true)
          } catch (error: any) {
            this.$message.error(error.message)
          }
        })
        .catch(() => null)
    },
    async publishOffer(id: string) {
      try {
        await publishOffer(id)
        this.$message({
          type: 'success',
          message: this.$t('bidding.confirm.publishOffer.acceptMessage'),
        })
        this.$store.commit('subscription/SET_IS_LOADING', true)
        this.closePublishSubscriptionDialog()
      } catch (error: any) {
        this.$message.error(error.message)
      }
    },
    resetOfferFormItems() {
      this.openEditSubscriptionDialog(this.selectedOffer)
    },
    closeOfferDialog() {
      this.selectedOffer = null
      this.selectedOfferFormItems = null
      this.fetchSubscriptions(null)
    },
    closeDialog() {
      this.fetchSubscriptions()
      this.selectedOffer = null
      this.selectedOfferFormItems = null
    },
    closeInfoDialog() {
      this.isInfoDialogVisible = false
    },
    onInfoDialogCTA() {
      if (this.organisationStatus === this.enums.OrganisationStatus.Draft) {
        this.$router.push({
          name: 'organisation',
          params: { id: this.user?.organisationId },
        })
      } else if (
        this.organisationStatus === this.enums.OrganisationStatus.Ready
      ) {
        this.closeInfoDialog()
      }
    },
    gerRedirectRouteName(offer: ISubscription) {
      const isOfferOwner = this.user?.organisationId === offer?.organisationId
      const offerHasBids = offer?.bids?.length

      return (isOfferOwner && offerHasBids) || !isOfferOwner
        ? 'contracts'
        : 'offer'
    },
    onOfferRedirect(offer: ISubscription) {
      if (this.isOrganisationRestricted) {
        this.$emitter.emit(Emitter.Restricted)

        return
      }

      this.$router.push({
        name: this.gerRedirectRouteName(offer),
        params: { id: offer.id },
      })
    },
  },
}
