






























































import { PropType } from 'vue'
import { IOffer, IBid } from '@/interfaces'
import { TransactionMixin } from '@/mixins/TransactionMixin'
import { OfferRecreate } from '@/components/offerDetail/OfferRecreate'
import { DeliveryFinalMessage } from '@/components/offerDetail/DeliveryPlanner/DeliveryFinalMessage'

export default {
  name: 'OfferFinalMessage',
  components: {
    OfferRecreate,
    DeliveryFinalMessage,
  },
  mixins: [TransactionMixin],
  inject: ['enums'],
  props: {
    offer: {
      type: Object as PropType<IOffer>,
      required: true,
    },
    bid: {
      type: Object as PropType<IBid>,
      default: () => ({}),
    },
    transactionNumber: {
      type: String,
      default: null,
    },
    offerOwner: {
      type: Boolean,
      default: false,
    },
    negotiationRevealed: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isBidAccepted() {
      return this.bid.bidStatus === this.enums.BidStatus.Accepted
    },
    isBidRejected() {
      return this.bid.bidStatus === this.enums.BidStatus.Rejected
    },
    isBidCanceled() {
      return this.bid.bidStatus === this.enums.BidStatus.Cancelled
    },
    isOfferInactive() {
      return [
        this.enums.OfferStatus.Rejected,
        this.enums.OfferStatus.Expired,
        this.enums.OfferStatus.Cancelled,
      ].includes(this.offer.offerStatus)
    },
    isContract() {
      return this.offer.contractType === this.enums.OfferContractType.Recurring
    },
    isOfferClosed() {
      return this.offer.offerStatus === this.enums.OfferStatus.Closed
    },
    isAnonymousMessageShown() {
      return (
        !(this.isBidCanceled && this.isOfferClosed) &&
        this.offer.isAnonymous &&
        this.offerOwner &&
        !this.negotiationRevealed
      )
    },
  },
}
