




















import { PropType } from 'vue'
import { OfferListItem } from '@/components/trading/OfferListItem'
import { IOffer, IOfferDescriptor, IUser } from '@/interfaces'
import { ITransactionsByOffers } from '@/modules/transaction/state'

export default {
  name: 'OfferList',
  components: {
    OfferListItem,
  },
  props: {
    offers: {
      type: Array as PropType<IOfferDescriptor[]>,
      required: true,
      default: () => [],
    },
    transactionsByOffers: {
      type: Object as PropType<ITransactionsByOffers>,
      default: () => {},
    },
    isDeliveriesAvailable: {
      type: Boolean as PropType<Boolean>,
      default: () => false,
    },
    isLoadingTransactions: {
      type: Boolean as PropType<Boolean>,
      default: () => false,
    },
    user: {
      type: Object as PropType<IUser>,
      default: () => null,
    },
  },
  methods: {
    onPublish(offer: IOffer) {
      this.$emit('publish', offer)
    },
    onClose(offer: IOffer) {
      this.$emit('close', offer)
    },
    onEdit(offer: IOffer) {
      this.$emit('edit', offer, true)
    },
    onClick(offer: IOffer) {
      this.$emit('redirect', offer)
    },
    onTransaction(offer: IOffer) {
      this.$emit('transaction', offer)
    },
  },
}
