




















import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: {
      type: Number,
      default: 0,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => props.value,
      set: (newValue: number) => {
        emit('input', newValue)
      },
    })

    const handleClickMinus = () => {
      model.value--
    }
    const handleClickPlus = () => {
      model.value++
    }

    return { model, handleClickMinus, handleClickPlus }
  },
})
