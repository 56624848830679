


















































import { DeliveryRawSyncStatus } from '@/enums'
import { IDeliveriesByDate } from '@/interfaces'
import { PropType } from 'vue'

export default {
  name: 'DeliveryInfo',
  props: {
    delivery: {
      type: Array as PropType<IDeliveriesByDate[]>,
      required: true,
    },
  },
  methods: {
    isDeliverySyncStatusOpen(status: string): boolean {
      return status === DeliveryRawSyncStatus.Open
    },
  },
}
