






































































































































import { mapActions, mapGetters } from 'vuex'
import { OfferInfo } from '@/components/offerDetail/OfferInfo'
import { MainContainer } from '@/components/common/MainContainer'
import { OfferHeader } from '@/components/offerDetail/OfferHeader'
import { Dialog } from '@/components/molecules/Dialog'
import BidForm from '@/components/bid/BidForm.vue'
import { acceptBid, cancelBid } from '@/utils/BidUtil/BidUtil'
import OfferSummary from '@/components/offer/OfferSummary.vue'
import OfferTypeBadge from '@/components/common/OfferTypeBadge.vue'
import { Tooltip } from '@/components/common/Tooltip'
import {
  AttemptType,
  BidStatus,
  OfferStatus,
  OrganisationStatus,
  TooltipPlacement,
} from '@/enums'
import { IAgreementAttempt, IContract } from '@/modules/contract/state'
import { RouteConfig } from 'vue-router'
import { OfferStatusMixin } from '@/mixins/OfferStatusMixin'
import { IBid } from '@/interfaces'

export default {
  name: 'OfferDetailsPage',
  components: {
    OfferInfo,
    MainContainer,
    CustomDialog: Dialog,
    BidForm,
    OfferSummary,
    OfferHeader,
    OfferTypeBadge,
    Tooltip,
  },
  mixins: [OfferStatusMixin],
  inject: ['enums'],
  data() {
    return {
      isBidDialogVisible: false,
      isAcceptBidDialogVisible: false,
      selectedBid: null,
      asideVisible: false,
      isLoading: true,
      TooltipPlacement,
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
      isGlobalAdmin: 'getIsGlobalAdmin',
      organisationStatus: 'getOrganisationStatus',
    }),
    ...mapGetters('offer', {
      offer: 'getOffer',
    }),
    ...mapGetters('contract', {
      contracts: 'getContracts',
      contractsAdmin: 'getContractsAdmin',
      agreementAttempts: 'getAgreementAttempts',
      selectedContractId: 'getSelectedContractId',
    }),
    ...mapGetters('materialFormatGroups', {
      materialFormatGroups: 'getMaterialFormatGroups',
    }),
    ...mapGetters('priceIndex', ['priceIndices']),
    ...mapGetters('transaction', [
      'offerTransactions',
      'isLoadingTransactions',
    ]),
    ...mapGetters('delivery', ['deliveries']),
    ...mapGetters('rating', [
      'publicRatings',
      'tradingPartners',
      'partnerRatings',
    ]),
    isBidCreateAllowed() {
      return (
        this.offer?.offerStatus === OfferStatus.Open &&
        !this.isOfferOwner() &&
        !this.offerHasOpenBids() &&
        this.organisationStatus === OrganisationStatus.Validated
      )
    },
    dialogTitle() {
      return this.offer
        ? `${this.$t('bidding.title.create')} ${this.$tc(
            `common.offerType.${this.offer?.offerType}`,
            1
          )}`
        : ''
    },
    goToContractText() {
      return this.$tc('common.myContract', this.isOfferOwner() ? 0 : 1)
    },
    isContractsRoute() {
      return this.$route.name === 'contracts'
    },
    isAllBidsRoute() {
      return this.$route.name === 'bids'
    },
    lastBid() {
      const bids = this.getBids()

      return bids ? bids[bids.length - 1] : undefined
    },
    hasBids() {
      return this.contracts && this.contracts.length
    },
    isTradingAvailable() {
      return (
        (this.offer?.organisationId !== this.user?.organisationId ||
          this.hasBids) &&
        this.organisationStatus === OrganisationStatus.Validated
      )
    },
    offerStatusClosed() {
      return this.offer?.offerStatus === this.enums.OfferStatus.Closed
    },
    isTransactionParticipant() {
      if (!this.user || !this.offer) {
        return false
      }
      const acceptedBid = this.offer.bids.find(
        (bid: IBid) => bid.bidStatus === BidStatus.Accepted
      )
      if (!acceptedBid) {
        return false
      }
      const participants = this.offer.bids
        .map((bid: IBid) =>
          bid.bidGroupId === acceptedBid.bidGroupId ? bid.organisationId : null
        )
        .filter(Boolean)

      if (!participants.includes(this.offer.organisationId)) {
        participants.push(this.offer.organisationId)
      }

      return participants.includes(this.user.organisationId)
    },
    isOfferStatusBadgeVisible() {
      return this.offerStatusClosed
        ? this.offer?.transactionParticipantIds?.includes(
            this.user?.organisationId
          )
        : true
    },
    isOfferAnonymous() {
      return this.offer?.isAnonymous
    },
    isBidHasCounterBids() {
      const bids = this.getBids()

      if (!bids) return false

      return (
        bids.some(
          (b: IBid) => b.organisationId !== this.user?.organisationId
        ) &&
        bids.some((b: IBid) => b.organisationId === this.user?.organisationId)
      )
    },
    isNegotiationHasMessage() {
      const attempts = this.agreementAttempts?.[this.selectedContractId]

      if (!attempts) return false

      return attempts
        .filter(
          (attempt: IAgreementAttempt) => attempt.type === AttemptType.Text
        )
        .some(
          (attempt: IAgreementAttempt) =>
            attempt.sender.organisationId === this.offer?.organisationId
        )
    },
    isInformationRevealed() {
      return {
        negotiation:
          !this.isOfferAnonymous ||
          (this.isOfferOwner() && this.isBidHasCounterBids),
        offer:
          !this.isOfferAnonymous ||
          this.isOfferOwner() ||
          this.isBidHasCounterBids,
        message:
          this.isOfferOwner() ||
          (this.isOfferAnonymous
            ? this.isNegotiationHasMessage || this.isBidHasCounterBids
            : true),
      }
    },
    isRecurringOffer() {
      return this.offer?.contractType === this.enums.OfferContractType.Recurring
    },
  },
  watch: {
    async $route(_: RouteConfig, prevRouteValue: RouteConfig) {
      if (prevRouteValue.name === 'deliveries') {
        await this.loadDeliveries(this.$route.params.id)
      } else {
        await this.fetchOfferAndContract()

        if (this.offerStatusClosed) {
          await this.fetchOfferTransactions(this.$route.params.id)
        }
      }
    },
  },
  async created() {
    await Promise.all([
      this.fetchOfferAndContract(),
      this.getMaterialFormatGroups(),
      this.loadPriceIndices(),
    ])

    if (!this.publicRatings) {
      await this.fetchPublicRatings()
    }

    if (!this.tradingPartners) {
      await this.fetchTradingPartners(this.user.organisationId)
    }

    if (this.offerStatusClosed && this.isTransactionParticipant) {
      await this.fetchOfferTransactions(this.$route.params.id)
      await this.loadDeliveries(this.$route.params.id)
    }
  },
  destroyed() {
    this.resetContractsState()
    this.resetOfferTransactions()
  },
  methods: {
    ...mapActions('offer', ['fetchOffer']),
    ...mapActions('contract', [
      'fetchContracts',
      'fetchContractsAdmin',
      'addBidAttempt',
      'cancelBidAttempt',
      'resetContractsState',
    ]),
    ...mapActions('auth', ['toggleUserProfileModal']),
    ...mapActions('materialFormatGroups', ['getMaterialFormatGroups']),
    ...mapActions('priceIndex', ['loadPriceIndices']),
    ...mapActions('transaction', [
      'fetchOfferTransactions',
      'resetOfferTransactions',
    ]),
    ...mapActions('delivery', ['loadDeliveries']),
    ...mapActions('rating', ['fetchPublicRatings', 'fetchTradingPartners']),
    isOfferOwner() {
      return this.offer?.organisationId === this.user?.organisationId
    },
    onCreateBid() {
      this.toggleUserProfileModal().then(() => {
        this.isBidDialogVisible = true
      })
    },
    closeBidDialog() {
      this.isBidDialogVisible = false
    },
    closeAcceptBidDialog() {
      this.isAcceptBidDialogVisible = false
    },
    offerTypeClass() {
      return `${this.offer?.offerType}-offer`
    },
    openAcceptBidDialog(bid: IBid) {
      this.selectedBid = bid
      this.isAcceptBidDialogVisible = true
    },
    offerHasOpenBids() {
      const bids = this.offer?.bids

      return bids
        ? !!bids.filter((bid: IBid) => bid.bidStatus === BidStatus.Open).length
        : false
    },
    getBids() {
      const contract = this.isOfferOwner()
        ? this.contracts.find(
            (contract: IContract) => contract.id === this.selectedContractId
          )
        : this.contracts[0]
      const offerBids = this.offer && this.offer.bids

      if (!contract || !offerBids) return undefined

      const contractBid: IBid = offerBids.find(
        (bid: IBid) => bid?.organisationId === contract?.organisationId
      )

      if (!contractBid) return undefined

      return offerBids.filter(
        (bid: IBid) => bid.bidGroupId === contractBid.bidGroupId
      )
    },
    async fetchOfferAndContract() {
      await this.fetchOffer(this.$route.params.id)
      await this.fetchContracts(this.offer)

      if (this.isGlobalAdmin) {
        await this.fetchContractsAdmin(this.offer)
      }

      this.isLoading = false
    },
    async onBidAccept() {
      try {
        await acceptBid({
          id: this.selectedBid.id,
          offerId: this.offer.id,
        })
        this.$message({
          type: 'success',
          message: this.$t('bidding.confirm.acceptBid.acceptMessage'),
        })
        this.closeAcceptBidDialog()
      } catch (error: any) {
        this.$message.error(error.message)
      }
    },
    onBidCancel(bid: IBid) {
      this.$confirm(
        this.$t('bidding.confirm.cancelBid.content', {
          cta: this.$t('common.ok'),
        }),
        this.$t('bidding.confirm.cancelBid.title'),
        {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.dialog.cancel'),
        }
      )
        .then(async () => {
          try {
            await this.cancelBidAttempt({
              bid,
              contractId: this.selectedContractId,
            })
            await cancelBid({
              id: bid.id,
              offerId: this.$route.params.id,
            })
            this.$message({
              type: 'success',
              message: this.$t('bidding.confirm.cancelBid.acceptMessage'),
            })
          } catch (error: any) {
            this.$message.error(error.message)
          }
        })
        .catch(null)
    },
    switchAside() {
      this.asideVisible = !this.asideVisible
    },
    async onBidSave(bid: IBid) {
      if (this.$route.name === 'contracts' && this.selectedContractId) {
        this.addBidAttempt({ contractId: this.selectedContractId, bid })
      } else {
        await this.fetchContracts(this.offer)
        await this.$router.push({ name: 'contracts' })
      }
    },
  },
}
