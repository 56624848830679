










































import { defineComponent, PropType, computed } from '@vue/composition-api'
import { IOffer } from '@/interfaces'
import enums from '@/enums'
import { ITransaction } from '@/modules/transaction/resources'
import TransactionStatusBadge from '@/components/common/TransactionStatusBadge.vue'
import { FloatingMenu } from '@/components/molecules/FloatingMenu'
import { ListItem } from '@/components/common/ListItem'

export default defineComponent({
  name: 'TransactionInfoLink',
  components: {
    TransactionStatusBadge,
    FloatingMenu,
    ListItem,
  },
  props: {
    offer: {
      type: Object as PropType<IOffer>,
      required: true,
      default: () => {},
    },
    transactions: {
      type: Array as PropType<ITransaction[]>,
      default: () => null,
    },
    isLoadingTransactions: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },
  },
  setup(props) {
    const isRecurringOffer = computed(
      () => props.offer?.contractType === enums.OfferContractType.Recurring
    )

    return { isRecurringOffer }
  },
})
