




import { PropType } from 'vue'
import { Dropdown } from '@/components/molecules/Dropdown'
import { CreatedBy } from '@/enums'

export default {
  name: 'CreatedBySelect',
  components: { Dropdown },
  props: {
    value: {
      type: String as PropType<CreatedBy | undefined>,
      default: undefined,
    },
  },
  computed: {
    createdBy: {
      get() {
        return this.value || null
      },
      set(createdBy: CreatedBy) {
        this.$emit('input', createdBy)
        this.$emit('change')
      },
    },
    dropdownItems() {
      const items = [{ text: this.$t('common.all'), value: null }] as any
      Object.values(CreatedBy).forEach((item) => {
        items.push({
          text: this.$t(`common.createdByFilter.${item}`),
          value: item,
        })
      })
      return items
    },
  },
}
